import logo from './DB_logo_v1.png';
import './App.css';
window.location.href = "https://chrome.google.com/webstore/detail/youtube-party/ngmncgebkdchkdokiecifpmodajbaopj";
function App() {
  return (
    <div className="App">
      <header className="App-header">

        <img src={logo} className="App-logo" alt="logo" />
        <p>
          DiscussionBar is now YouTube party.  
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          You will be redirected to the Chrome Store page.
        </a>
      </header>
    </div>
  );
}

export default App;
